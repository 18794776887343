import { Env } from '@app/types/env';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment: Env = {
  appVersion: `${require('../../package.json').version}-qa`,
  production: true,
  apiBaseUrl: 'https://pre-apim.integration.bacardiapps.com/v1/transaction/checklists',
  apiBaseUrlIdea: 'https://pre-apim.integration.bacardiapps.com/v01/ideapp/qa',
  apiManagmentKey: 'k8BK2UACgP4V2YkqJRUSNFHgMiqG2XKc',
  logLevel: NgxLoggerLevel.ERROR,
  clientId: '1ac140d9-1b38-4688-a2bb-453dcfa61fbf',
  authority: 'https://login.microsoftonline.com/9e16780e-719d-496b-a880-24ac20c30a60',
  azureRoot: 'pre',
  clientSecretOauth: 'WpGVCbGc0yszo1Nm',
  endpointAccessToken: 'https://pre-apim.integration.bacardiapps.com/v1/oauthv2/cors/token',
  powerApplink: 'https://app.powerbi.com/groups/0fba6035-3ba3-4f3b-ad49-2a6da9a672a9/reports/5a0b5836-b829-4fb1-ab3b-db971555529e?ctid=9e16780e-719d-496b-a880-24ac20c30a60&pbi_source=linkShare',
  ideaUid: '',
  clientIdGraphs: 'acca9fb1-6f23-4a07-b3cb-2fcb4439ebe2',
  clientSecretGraphs: 'A5d7Q~TcST3x6wb3btVgBXIDnLU6gvFtpAC2D',
  endpointGraphsAccessToken: 'https://login.microsoftonline.com/9e16780e-719d-496b-a880-24ac20c30a60/oauth2/token'
};
